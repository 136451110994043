<template>
  <v-app-bar app clipped-left color="var(--primary)" elevation="0">
    <v-app-bar-nav-icon
      class="d-block d-lg-none"
      color="var(--white)"
      @click="$emit('open-drawer', !drawer)"
    ></v-app-bar-nav-icon>
    <v-btn
      v-if="backLink"
      class="align-self-center mr-xl-n10"
      icon
      elevation="0"

      color="var(--white)"
      :to="{ path: backLink }"
    >
      <v-icon icon="mdi-arrow-left"
    /></v-btn>

    <v-container>

        <h4 v-if="topbartitle" class="text-white align-self-center">
          {{ topbartitle }}
        </h4>

    </v-container>
  </v-app-bar>
</template>

<script>
export default {
  props: {
    topbartitle: {
      type: String,
      required: false,
    },
    backLink: {
      type: String,
      required: false,
    },
    drawer: Boolean,
  },
  data: () => ({}),
  created() {
    if (window.innerWidth > 768) {
      this.$emit("open-drawer", true);
    }
  },
};
</script>
