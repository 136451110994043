<template>
    <div>
        <div class="dropzone" v-bind="getRootProps()" v-if="!loading">
            <input type="file" @input="handleUpload()" formenctype="multipart/form-data"
                v-bind="getInputProps({ multiple: false })" />
            <div class="dropzone-content">
                <slot v-if="!isDragActive"></slot>
            <slot v-if="isDragActive" name="dragActive"></slot>
            </div>
            <slot v-if="errorMessage" name="error">
                <div v-if="errorMessage" class="text-primary">{{ errorMessage }}</div>
            </slot>

        </div>
    </div>
</template>
<script setup>
import { watch, reactive, defineProps, defineEmits, onUnmounted, ref } from 'vue';
import { useDropzone } from 'vue3-dropzone';

const props = defineProps({

    layout: {
        type: String,
        default: undefined,
    },
    acceptedTypes: {
        type: String,
        default: undefined,
    },

    type: {
        type: String,
        default: undefined,
    },

    loading: {
        type: Boolean,
        default: undefined,
    },
    index: {
        type: Number,
        default: undefined,
    },
    parentData: {
        type: Object,
        default: undefined,
    },

});

const errorMessage = ref('');

let emit = defineEmits(['accepedtTypes'])
const data = reactive({
    files: new Set(),
});
const allData = ref()

let filesWithPreview = []
const onDrop = (acceptFiles) => {
    emit('acceptFiles', acceptFiles);

    if (acceptFiles.length > 0 && props.acceptedTypes.includes(acceptFiles[0].type) || acceptFiles.length > 0 && props.acceptedTypes.length == 0) {
        allData.value = acceptFiles
   
        emit('currentType', acceptFiles[0].type);

        data.value = acceptFiles;
        const uniqueFiles = acceptFiles.filter(file => !data.files.has(file.path));
        filesWithPreview = uniqueFiles.map(file => ({ ...file, preview: URL.createObjectURL(file) }));
        data.files = new Set([...data.files, ...filesWithPreview]);
        emit('previews', filesWithPreview)
        errorMessage.value = null
} else {
        allData.value = null
        errorMessage.value = `Invalid file type. Should be (${props.acceptedTypes}).`;
    }
};

const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: props.acceptedTypes,
});

const handleUpload = () => {
    // emit('file-upload', e);
    // state.files.push(e);
};


const handleDeleteFiles = () => {
    data.files.forEach(file => URL.revokeObjectURL(file.preview));
    data.files.clear();

    emit('previews', []);
};

watch(() => props.parentData, (newVal) => {
    data.files = new Set(newVal)
    // console.log('Files data changed:', newVal);

});


onUnmounted(() => {
    handleDeleteFiles();

    data.files.forEach(file => URL.revokeObjectURL(file.preview));
});
</script>