import { defineStore } from 'pinia'

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useMainStore = defineStore('store', {
    state: () => {
        return {
          // all these properties will have their type inferred automatically
          user: {
			id: -1,
			firstname: '',
			lastname: '',
			phonenumber: '',
			email: '',
			role: 0,
			profilepicture: '',
			title: '',
			bio: '',
			dateofbirth: '',
			register_date: 0,
			perms: [],

		},
        }
      },
    })


