<template>
  <v-menu offset-y close-on-content-click="false">
    <template v-slot:activator="{ props }">
      <v-text-field
        v-bind="props"
        hide-details
        density="compact"
        variant="solo"
        :value="editcolor"
        @click.stop
      >
        <template #append-inner="{}">
          <div
            :style="`width: 25px; height: 25px;  border-radius: 100px; margin-right: 10px; background-color:${editcolor}`"
          ></div>
        </template>
      </v-text-field>
      <k-spacing y="2"></k-spacing>
      <p style="font-size: 1.2rem">{{ title }}</p>
    </template>

    <v-color-picker
      dot-size="28"
      mode="hexa"
      v-model="editcolor"
      @click.stop
    ></v-color-picker>

  </v-menu>
</template>

<script>
export default {
  props: {
    color: String,
    theme: String,
    title: String,
  },
  mounted() {
    if (this.editcolor == null) {
      this.editcolor = this.color;
    }
  },
  watch: {
    color() {
      this.editcolor = this.color;
    },
    editcolor() {
      if (this.editcolor) {
        this.$emit("color", this.editcolor);
      }
    },
  },
  data: () => ({
    editcolor: null,
  }),
};
</script>
